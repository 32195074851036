import { ButtonProps, ButtonType, HTMLButtonType } from "../types";

export function ButtonFill({
  htmlType = HTMLButtonType.button,
  ...props
}: ButtonProps): JSX.Element | undefined {
  const { type, args, handler, handlerFunction, children } = props;
  return (
    <button
      type={htmlType !== undefined ? htmlType : "button"}
      className={
        type === ButtonType.Outlined
          ? "focus-visible::bg-blue-dark inline-flex w-fit items-center justify-start gap-2 rounded-3xl border-4 border-white px-5 py-2 active:bg-blue-dark"
          : type === ButtonType.Underlined
            ? "inline-flex w-fit items-center justify-center gap-2 border-b-2 border-white hover:border-blue focus:border-blue focus:bg-blue/50 active:border-blue active:bg-blue/50"
            : "inline-flex w-fit items-center justify-center gap-2 focus-visible:text-blue active:text-blue md:hover:text-blue"
      }
      onClick={
        args === undefined || handler !== undefined
          ? handler
          : handler !== undefined
            ? // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              () => handlerFunction?.(args)
            : undefined
      }
    >
      {children}
    </button>
  );
}
