import { useState, useEffect, useReducer, useRef, Reducer } from "react";

import "../output.css";
import {
  initDB,
  addImage,
  deleteImage,
  deleteAll,
  copyImage,
  getImage,
  setHasUpdate,
  checkHasUpdate,
} from "./imageDB";
import InfoModal from "./components/InfoModal";
import CardCreator, {
  FormActionType,
  SpellCardInfo,
} from "./components/CardCreator";
import CardTable from "./components/CardTable";
import { SpellBook } from "./components/SpellBook/SpellBook";
import {
  formReducer,
  INITIAL_STATE,
} from "./components/CardCreator/formReducer";
import { FORM_ACTIONS } from "./components/CardCreator/constants";
//import { WizardAction, wizardReducer, WizardState } from "./wizardReducer";

function SpellCardWizard() {
  // * Variables ---------------------------------------------
  // State for input fields from formReducer
  const [fieldStates, dispatch] = useReducer<
    Reducer<SpellCardInfo, FormActionType>
  >(formReducer, INITIAL_STATE);

  // const [wizardState, dispatch] = useReducer<
  //   Reducer<WizardState, WizardAction>
  // >(wizardReducer, INITIAL_STATE);

  // State for CardCreator menu
  const [isCollapse, setCollapse] = useState<boolean>(false);

  // State to keep track of editing
  const [isEditing, setIsEditing] = useState<boolean>(false);

  // * Local Storage Initialization ---------------------------
  // Set userCards state & duplicate to local storage

  const [userCards, setUserCards] = useState(() => {
    const savedCards: string | null = localStorage.getItem("cards");
    let initialValue: SpellCardInfo[] = [];
    if (typeof savedCards === "string") {
      initialValue = JSON.parse(savedCards) as SpellCardInfo[];
    }
    return initialValue;
  });

  // Duplicate nextID to local storage
  const [nextId, setNextID] = useState<number>(() => {
    const savedNextId = localStorage.getItem("nextid");
    let initialValue: number = 0;
    if (typeof savedNextId === "string") {
      initialValue = JSON.parse(savedNextId) as number;
    }
    return initialValue;
  });

  // Update Storage
  useEffect(() => {
    //Store data
    console.log("Updating localStorage...");
    localStorage.setItem("cards", JSON.stringify(userCards));
    localStorage.setItem("nextid", JSON.stringify(nextId));
  }, [userCards, nextId]);

  // Create database
  useEffect(() => {
    const createDB = async () => {
      try {
        const status = await initDB();
        console.log("IndexedDB created: " + status);
      } catch (error) {
        console.log(error);
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    createDB();
  }, []);
  // ! The blank dependency makes this only load once when the app mounts

  // * Duplicate User Cards to Spellbook
  const [spellbook, setSpellbook] = useState([...userCards]);

  //Update Spellbook when userCards changes
  useEffect(() => {
    console.log("Setting Spellbook...");
    console.log(userCards);
    setSpellbook(JSON.parse(JSON.stringify(userCards)) as SpellCardInfo[]);
  }, [userCards]);

  // * App Functions ----------------------------------------------
  /**
   * Toggle state of the CardCreator.
   * @var isCollapse
   */
  const toggleCreator = () => {
    if (isEditing && !isCollapse) {
      if (window.confirm("Are you sure you'd like to stop editing?")) {
        setIsEditing(false);
        setCollapse(!isCollapse);
      } else {
        console.log("Cancelled");
      }
    } else {
      setCollapse(!isCollapse);
    }
  };

  // State for CardIndex
  const [showIndex, setShowIndex] = useState(
    window.innerWidth < 768 ? false : true,
  );

  /**
   * Toggle state of the CardCreator.
   */
  const toggleIndex = () => {
    setShowIndex(!showIndex);
  };

  const [showInfoModal, setShowInfoModal] = useState(false);

  const toggleInfoModal = () => {
    setShowInfoModal(!showInfoModal);
  };

  /**
   * Reset fields in card creator
   */
  const resetFields = () => {
    console.log("Fields Reset!");
    //Reset Field State Values
    dispatch({ type: FORM_ACTIONS.RESET });
  };

  /**
   * Gets number form card ID string
   * @param {string} id - element's id
   * @param {string} splitChar - Character used to split the string
   * @returns {number} Integer value of card ID
   * @example
   * //id = "cardindex-0"
   * parseCardId(id,"-");
   * //returns 0
   */
  function parseCardId(id: string, splitChar: string): number | undefined {
    const idString: string | undefined = id.split(`${splitChar}`).pop();
    if (idString !== undefined) {
      return parseInt(idString);
    } else {
      console.error("Unable to convert card id into a number");
    }
  }

  /**
   * Submit inputs and create a card
   * @param {*} event - Submit event
   */
  const createCard = async (event: React.FormEvent<HTMLFormElement>) => {
    //Prevent from submitting form
    event.preventDefault();

    //console.log("createCard is being run...");

    //Check if isEditing
    if (isEditing === true) {
      console.log("Updating card ...");
      // Create copy of userCards
      const editedUserCards = [...userCards];

      // Check if image has been changed
      const editedCard = editedUserCards.find(
        (card) => fieldStates.id === card.id,
      );

      // Add image to indexedDB BEFORE updating the userCards
      if (
        editedCard !== undefined &&
        fieldStates.spellimg.value !== editedCard.spellimg.value &&
        typeof fieldStates.spellimg.value === "string" &&
        fieldStates.id
      ) {
        //Add image to imageStorage
        await addImage(fieldStates.id, fieldStates.spellimg.value);
      } else {
        await setHasUpdate(true);
      }

      //Replace card at the current id
      editedUserCards.map((card) => {
        console.log(fieldStates.id, card.id);
        if (fieldStates.id === card.id) {
          Object.assign(card, {
            ...fieldStates,
            spellimg: { ...fieldStates.spellimg, value: fieldStates.id },
          });
          //console.log("The card has been replaced");
        }
        return card;
      });

      //Set Cards
      setUserCards(editedUserCards);

      //Turn off edit mode
      setIsEditing(!isEditing);
    } else {
      // Is NOT Editing, proceed with normal card creation

      // If and image was set
      if (
        typeof fieldStates.spellimg.value === "string" &&
        fieldStates.spellimg.value !== "none"
      ) {
        //set value of image
        await addImage(nextId, fieldStates.spellimg.value);

        // set user card
        setUserCards([
          {
            ...fieldStates,
            id: nextId,
            zindex: nextId + 1,
            spellimg: { ...fieldStates.spellimg, value: nextId },
          },
          ...userCards,
        ]);
      } else {
        await setHasUpdate(true);

        // set user card
        setUserCards([
          {
            ...fieldStates,
            id: nextId,
            zindex: nextId + 1,
          },
          ...userCards,
        ]);
      }

      setNextID(nextId + 1);
    }

    //Reset Input Fields
    resetFields();

    // If on Mobile, close the creator
    if (window.innerWidth < 768) {
      setCollapse(!isCollapse);
    }
  };

  /**
   * Activates edit mode for selecte card
   * @param {string} id - ID of the card being editted
   */
  const editCard = (id: string) => {
    console.log("Editing Card...");

    //Copy selected card to helper variable
    const editedCard = spellbook.find(
      (item) => item.id === parseCardId(id, "_"),
    );

    //Turn On "Edit Mode"
    setIsEditing(true);

    //Set New Card and Inputs to selected Card but remove the extra object keys?? or maybe keep the id?
    console.log(editedCard);
    dispatch({ type: FORM_ACTIONS.SET_FIELDS, payload: editedCard });

    //Open Card Creator
    setCollapse(false);

    //Close Spellbook on smaller screens
    if (window.innerWidth < 768) {
      setShowIndex(false);
    }
  };

  /**
   * Cancels editting mode
   */
  const cancelEdit = () => {
    setIsEditing(!isEditing);
    resetFields();
    setCollapse(!isCollapse);
  };

  /**
   * Duplicates selected card
   * @param {string} id - Element id of the card being copied
   * @param {number} order - The order of the card in the list
   */
  const copyCard = async (id: string, order: number) => {
    console.log("Copying card...");
    console.log("Card id =", id);
    // Get current id as a number
    const currentId: number | undefined = parseCardId(id, "_");

    //Shallow copy of current nextId
    const copiedCardId: number = nextId;

    const copyOfUserCards = [...userCards];

    //Create new entry in IDB with the same image
    if (currentId !== undefined) {
      // Copy card
      let copiedCard: SpellCardInfo | undefined = copyOfUserCards.find(
        (item) => item.id === currentId,
      );

      if (copiedCard !== undefined) {
        //If the card does have an image, update database first
        if (copiedCard.spellimg.value !== "none") {
          await copyImage(currentId, copiedCardId);

          copiedCard = {
            ...copiedCard,
            id: copiedCardId,
            spellname: {
              ...copiedCard.spellname,
              value: `${copiedCard.spellname.value}*`,
            },
            spellimg: {
              ...copiedCard.spellimg,
              value: copiedCardId,
            },
          };
        }
        // If it does NOT have an image, don't change the value of spellimg
        else {
          await setHasUpdate(true);

          copiedCard = {
            ...copiedCard,
            id: copiedCardId,
            spellname: {
              ...copiedCard.spellname,
              value: `${copiedCard.spellname.value}*`,
            },
          };
        }

        console.log("Copied card:", copiedCard);

        console.log("The ID of the card copied is: " + copiedCard.id);

        setNextID(nextId + 1);

        console.log("User Cards: ", userCards);

        setUserCards([
          ...userCards.slice(0, order + 1),
          copiedCard,
          ...userCards.slice(order + 1),
        ]);
      } else {
        console.error("copiedCard is undefined");
      }
    }
  };

  /**
   * Deletes selected card
   * @param {string} id - Element id of the card being copied
   * @param {string} splitChar - split char for target element's id
   */
  const deleteCard = async (id: string, splitChar: string) => {
    console.log("Deleting card ...");
    //console.log("parseCardId(id,splitChar)");

    //Remove image associated with card from IndexedDB
    const cardId = parseCardId(id, splitChar);
    console.log(cardId);

    if (cardId !== undefined) {
      console.log("awaiting deleteImage");
      await deleteImage(cardId);
      console.log("deleteImage complete");
    }

    //Remove card at index of card
    setUserCards(userCards.filter((a) => a.id !== cardId));
    // Spellbook is updated after this
  };

  /**
   * Deletes all cards from userCards/localStorage
   */
  const clearCards = async () => {
    //Reset IndexedDB
    await deleteAll();
    //Reset user cards
    setUserCards([]);
    //Reset id
    setNextID(0);
    //Reset localStorage
    localStorage.removeItem("cards");

    console.log("Cards Cleared");
  };

  // * useRef() stuff to highlight selected index - still under construction
  const focusRef = useRef<HTMLDivElement>(null);

  const [focusedCard, setFocusedCard] = useState<number>();

  const focusCard = (id: number) => {
    //console.log("Focussing on card " + id);
    setFocusedCard(id);

    const cardTable = focusRef.current;
    //console.log(cardTable);
    if (cardTable !== null) {
      const focusedSpellCard = cardTable.querySelector(`[data-id="${id}"]`);
      //console.log("Focused Spell Card:", focusedSpellCard);
      if (focusedSpellCard !== null) {
        focusedSpellCard.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      } else {
        console.warn(`No elements were found matching [data-id="${id}"]`);
      }
    } else {
      console.error("focusRef.current is not an element");
    }
  };

  const [sort, setSort] = useState<string>("");

  function raiseCard(id: number) {
    //console.log("the card selected was: " + id);

    //Create shallow copy of spellbook
    let orderedSpells: SpellCardInfo[] = [...spellbook];
    //console.log("Spells have been copied:");

    //let topCard = orderedSpells.find((spell) => spell.id === cardId);
    //let topCard = orderedSpells.find((spell) => spell.id === id).zindex;

    //console.log(topCard);

    orderedSpells = orderedSpells.map((card) => {
      const newCard: SpellCardInfo = { ...card };
      if (card.id === id) {
        newCard.zindex = spellbook.length;
      } else {
        if (newCard.zindex !== undefined) {
          newCard.zindex -= 1;
        } else {
          console.error("z-index is undefined");
        }
      }
      return newCard;
    });
    console.log(orderedSpells);

    setSpellbook(orderedSpells);
  }

  // * Image retrieval functions -----------------------------

  /**
   * Retrieves the spell card's image from the database
   * @param card
   * @returns
   */
  async function retrieveImage(card: SpellCardInfo) {
    try {
      if (typeof card.spellimg.value === "number") {
        const imageData: string = await getImage(card.spellimg.value);
        if (imageData !== undefined) {
          console.log(typeof imageData);
          console.log("Image data retrieved");
          return imageData;
        } else {
          console.error("No image data found");
        }
      }
    } catch (err) {
      console.error("There has been an error retrieving the image:", err);
    }
  }

  // useEffect to update all the images when the spellbook is updated
  useEffect(() => {
    console.log("useEffect() => retrieveImage()");
    let updatedSpellBook: SpellCardInfo[] = [...spellbook];

    /**
     * Updates the images in the spellbook whenever an image has been added/modified
     * @returns {void}
     */
    const retrieveImages = async () => {
      console.log("... Retrieving images");
      try {
        const hasUpdate = await checkHasUpdate();

        if (hasUpdate === true) {
          console.log("Database has been updated...");
          updatedSpellBook = await Promise.all(
            updatedSpellBook.map(async (card) => {
              if (card.id === -1 && typeof card.spellimg.value !== "number") {
                //console.log("This is the preview card");
                const image = card.spellimg.value;
                card.spellimg.value = image;

                return card;
              } else if (card.id === -1) {
                //console.log("Retrieving image for preview (card " + card.id + ")");
                const image = await retrieveImage(card);
                if (image !== undefined) {
                  card.spellimg.value = image;
                } else {
                  console.error("No image was found");
                }

                return card;
              } else if (typeof card.spellimg.value === "number") {
                console.log("Retrieving image for card " + card.id);
                const image = await retrieveImage(card);
                if (image !== undefined) {
                  console.log(typeof image);
                  card.spellimg.value = image;
                } else {
                  console.error("Image is undefined");
                }
                return card;
              } else {
                console.log(typeof card.spellimg.value);
                console.log("No image to retrieve");

                return card;
              }
            }),
          );
          // Update the spellbook
          console.log("Spellbook has changed");
          //console.log(updatedSpellBook);
          setSpellbook([...updatedSpellBook]);

          // Tell the database that the cards have been changed
          await setHasUpdate(false);
        } else {
          console.log("No changes to database made - spells not updated");
        }
      } catch (error) {
        console.error(JSON.stringify(error));
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    retrieveImages();
  }, [spellbook]);

  return (
    <div
      className="flex h-dvh flex-col items-center md:h-fit md:flex-row md:items-start md:justify-between print:!block print:break-inside-avoid print:overflow-visible"
      data-print="true"
    >
      <CardCreator
        isCollapse={isCollapse}
        toggleCreator={toggleCreator}
        toggleInfoModal={toggleInfoModal}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        createCard={createCard}
        isEditing={isEditing}
        cancelEdit={cancelEdit}
        dispatch={dispatch}
        fieldStates={fieldStates}
      />

      {showInfoModal ? <InfoModal toggleModal={toggleInfoModal} /> : null}

      <CardTable
        cards={spellbook}
        focusedCard={focusedCard}
        isCollapse={isCollapse}
        showIndex={showIndex}
        raiseCard={raiseCard}
        sort={sort}
        focusRef={focusRef}
      />

      <SpellBook
        userCards={userCards}
        showIndex={showIndex}
        toggleIndex={toggleIndex}
        setSort={setSort}
        spellbook={spellbook}
        setSpellbook={setSpellbook}
        clearCards={clearCards}
        editCard={editCard}
        copyCard={copyCard}
        deleteCard={deleteCard}
        isEditing={isEditing}
        raiseCard={raiseCard}
        focusCard={focusCard}
      />
    </div>
  );
}
export default SpellCardWizard;
