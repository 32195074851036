import { FORM_ACTIONS } from "./constants";
import {
  DualInput,
  FormActionType,
  SpellCardFields,
  SpellCardInfo,
} from "./types";

export const INITIAL_STATE: SpellCardFields = {
  spellname: { value: "", visibility: true, optional: false },

  spelllevel: { value: "", visibility: true, optional: false },
  spellschool: { value: "", visibility: true, optional: false },
  spelltype: { value: "", visibility: true, optional: false },

  casttime: { value: "", visibility: true, optional: false },
  range: {
    value: { input: "", unit: "" },
    visibility: true,
    optional: false,
  },
  duration: { value: "", visibility: true, optional: false },
  spelltarget: { value: "", visibility: true, optional: true },
  damage: {
    value: { input: NaN, unit: "" },
    visibility: true,
    optional: true,
  },

  spelldesc: { value: "", visibility: true, optional: true },
  upcastdesc: { value: "", visibility: true, optional: true },

  spellcomp: {
    value: [false, false, false],
    visibility: true,
    optional: false,
  },
  spellritual: { value: false, visibility: true, optional: false },
  spellcon: { value: false, visibility: true, optional: false },

  spellcolor: { value: "#A1E5F3", visibility: true, optional: false },
  spellimg: { value: "none", visibility: true, optional: false },
};

export const formReducer = (
  state: SpellCardFields,
  action: FormActionType,
): SpellCardInfo => {
  const { type, field, payload } = action;

  //console.log("Dispatching Event");
  switch (type) {
    case FORM_ACTIONS.RESET:
      return {
        ...INITIAL_STATE,
      };
    case FORM_ACTIONS.SET_FIELDS:
      if (payload) {
        return {
          ...payload,
        };
      } else {
        return state;
      }
    case FORM_ACTIONS.SET_INPUT:
      //console.log(field.name);
      //console.log(state.spellname.value);
      if (field !== undefined && field?.name !== undefined) {
        return {
          ...state,
          [field.name]: {
            ...state[field.name],
            value: field.value,
          },
        };
      } else {
        return state;
      }
    case FORM_ACTIONS.SET_INPUTSELECT:
      if (
        field !== undefined &&
        field.name !== undefined &&
        state[field.name] !== undefined &&
        typeof state[field.name].value !== "string"
      ) {
        if (field.input === "select") {
          return {
            ...state,
            [field.name]: {
              ...state[field.name],
              value: {
                ...(state[field.name].value as DualInput),
                unit: field.value,
              } as DualInput,
            },
          };
        } else {
          return {
            ...state,
            [field.name]: {
              ...state[field.name],
              value: {
                ...(state[field.name].value as DualInput),
                input: field.value,
              } as DualInput,
            },
          };
        }
      } else {
        return state;
      }
    case FORM_ACTIONS.SET_CHECKBOX:
      console.log("checkbox handled");
      if (field !== undefined && field?.name !== undefined) {
        return {
          ...state,
          [field.name]: {
            ...state[field.name],
            value: !state[field.name]["value"],
          },
        };
      } else {
        return state;
      }
    case FORM_ACTIONS.SET_MULTISELECT:
      //console.log(field.id);
      if (field !== undefined && field?.id !== undefined) {
        const compArray = state.spellcomp.value.map((item, index) => {
          //console.log(item);
          if (index === field.id) {
            return !item;
          } else {
            return item;
          }
        });
        const compTuple: [boolean, boolean, boolean] = [
          compArray[0],
          compArray[1],
          compArray[2],
        ];
        return {
          ...state,
          spellcomp: {
            ...state.spellcomp,
            value: compTuple,
          },
        };
      } else {
        return state;
      }
    case FORM_ACTIONS.SET_VIS:
      if (field !== undefined && field?.name !== undefined) {
        console.log(state[field.name]["visibility"]);
        if (state[field.name]["optional"] === true) {
          return {
            ...state,
            [field.name]: {
              ...state[field.name],
              visibility: !state[field.name]["visibility"],
            },
          };
        } else {
          return state;
        }
      } else {
        return state;
      }
    case FORM_ACTIONS.SET_IMAGE:
      //console.log(field.value);
      if (
        field !== undefined &&
        field?.name !== undefined &&
        field.value !== undefined &&
        typeof field.value !== "boolean"
      ) {
        return {
          ...state,
          spellimg: {
            ...state.spellimg,
            value: field.value,
          },
        };
      } else {
        return state;
      }
    case FORM_ACTIONS.SET_COLOR:
      if (field !== undefined && field?.name !== undefined) {
        return {
          ...state,
          [field.name]: {
            ...state[field.name],
            value: field.value,
          },
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};
