import { Octokit } from "octokit";
import { MouseEventHandler } from "react";
import { releaseData, UPDATE } from "./types";
import { ButtonType } from "../Button/types";
import { parseReleases } from "./utils";
import { UpdateLogEntry } from "./subcomponents/UpdateLogEntry";
import { UpdateLogSection } from "./subcomponents/UpdateLogSection";
import Button from "../Button/Button";

const octokit = new Octokit({
  auth: "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",
});

const fetchedReleases = await octokit.paginate(
  "GET /repos/camdotod/spell-card-wizard/releases",
  {
    owner: "camdotod",
    repo: "spell-card-wizard",
    headers: {
      "X-GitHub-Api-Version": "2022-11-28",
    },
  },
);

// * Component
export default function InfoModal(props: { toggleModal: MouseEventHandler }) {
  const updateLog: releaseData[] = [...parseReleases(fetchedReleases)].map(
    (item: releaseData, index, array) => {
      const splitVersion = item.version.split(".").slice(1).map(Number);
      // splitVersion.forEach((substring) => parseInt(substring));

      const prevSplitVersion = array[index + 1]?.version
        .split(".")
        .slice(1)
        .map(Number);
      //prevSplitVersion.forEach((substring) => parseInt(substring));

      console.log(splitVersion, prevSplitVersion);

      if (prevSplitVersion === undefined) {
        return { ...item, update: UPDATE.MAJOR };
      } else if (splitVersion[0] > prevSplitVersion[0]) {
        return { ...item, update: UPDATE.MAJOR };
      } else if (splitVersion[1] > prevSplitVersion[1]) {
        return { ...item, update: UPDATE.MINOR };
      } else {
        return { ...item, update: UPDATE.PATCH };
      }
    },
  );

  return (
    <div
      id="info-modal"
      className="absolute z-50 flex h-dvh w-full flex-col items-start justify-start overflow-auto bg-black px-8 py-6 font-sans text-white md:bottom-0 md:overflow-hidden md:px-16 md:py-16"
    >
      <nav
        id="info-header"
        className="flex items-center justify-start gap-4 self-stretch pb-4"
      >
        <div
          id="full-screen-button"
          className="material-symbols-outlined text-4xl"
        >
          help
        </div>
        <div
          id="info-button"
          className="mr-auto flex items-center gap-2 p-2 text-2xl font-bold"
        >
          Info
        </div>

        <Button
          type={ButtonType.None}
          size="4xl"
          icon="close"
          handler={props.toggleModal}
        />
      </nav>

      <div
        id="info-modal-content"
        className="flex flex-1 flex-col items-start justify-start gap-4 self-stretch md:flex-row md:gap-10"
      >
        <div
          id="left-column"
          className="peer/left flex flex-col items-start justify-start gap-4 self-stretch pb-6 md:flex-1 md:basis-0 md:gap-12 md:has-[details[open]]:w-2/3"
        >
          <UpdateLogSection
            id="how-to-use"
            summary="How to Use"
            icon="book_4_spark"
            defaultOpen={true}
          >
            <>
              <div className="flex flex-col gap-2">
                <h4 className="text-xl font-medium">Creating Spell Cards</h4>
                <p className="opacity-70">
                  To create a spell card, simply enter the information into the
                  form and press <strong>“Create Card”</strong>.
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <h4 className="text-xl font-medium">Using the Spellbook</h4>
                <p className="opacity-70">
                  The Spellbook can be used to search, sort, and filter your
                  spell cards. You can also edit, copy, and delete your spell
                  cards by clicking on them to expand.
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <h4 className="text-xl font-medium">Exporting Spell Cards</h4>
                <p className="opacity-70">
                  The spell cards in your spellbook can be{" "}
                  <strong>exported to a PDF for print</strong>
                  . <br />
                  <br /> Exporting to JSON is planned for a future update!
                </p>
              </div>
            </>
          </UpdateLogSection>

          <UpdateLogSection
            id="keyboard-shortcuts"
            summary="Keyboard Shortcuts"
            icon="keyboard"
          >
            <p className="opacity-70">Coming soon!</p>
          </UpdateLogSection>
        </div>
        <div
          id="right-column-update-log"
          className="flex max-w-none flex-col gap-4 self-stretch border-white peer-has-[details[open]]/left:max-w-md md:border-l md:pl-6"
        >
          <details className="group/details items-start justify-start gap-5 self-stretch bg-black">
            <summary className="flex flex-row-reverse items-start justify-between gap-2 border-b border-white/30 py-3 md:flex-row md:justify-start">
              <span className="material-symbols-outlined opacity-70 group-open/details:rotate-180 md:rotate-90 md:group-open/details:-rotate-90">
                keyboard_arrow_down
              </span>
              <div className="flex items-center gap-2">
                <span className="material-symbols-outlined">handyman</span>
                <h3 className="text-2xl font-bold">Update Log</h3>
              </div>
            </summary>
            <div className="flex flex-col items-start justify-start gap-4 overflow-y-auto">
              {updateLog.length > 0 ? (
                updateLog.map((update, index) => {
                  return <UpdateLogEntry key={index} updateData={update} />;
                })
              ) : (
                <p className="py-8 text-white/60">
                  Spell Card Wizard is still in development! Stay tuned for the
                  first release!🔮
                </p>
              )}
            </div>
            <a
              id="github-link"
              href="https://github.com/camdotod/spell-card-wizard"
              target="blank"
              className="mt-4 flex w-fit items-center justify-center gap-2 border border-white p-2 text-right text-base font-medium"
              tabIndex={0}
            >
              <span className="material-symbols-outlined mr-2 align-middle">
                link
              </span>
              GitHub Repository
            </a>
          </details>
        </div>
      </div>
    </div>
  );
}
