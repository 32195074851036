import { MouseEventHandler } from "react";

export enum ButtonType {
  Outlined = "OUTLINED",
  Underlined = "UNDERLINED",
  None = "NONE",
}

export enum HTMLButtonType {
  button = "button",
  submit = "submit",
  reset = "reset",
}

export interface ButtonLabelProps {
  size?: string;
  weight?: string;
  label?: string;
}

export interface ButtonProps extends ButtonLabelProps {
  type: ButtonType;
  icon?: string;
  handler?: MouseEventHandler;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  handlerFunction?: Function;
  args?: unknown;
  htmlType?: HTMLButtonType;
  children?: JSX.Element;
}
