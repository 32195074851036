import { ButtonFill } from "./subcomponents/ButtonFill";
import { ButtonLabel } from "./subcomponents/ButtonLabel";
import { ButtonProps } from "./types";

export default function Button(props: ButtonProps): JSX.Element {
  const { args, handler, handlerFunction, icon, label, size, weight } = props;

  const iconStyle = `material-symbols-outlined text-${size} font-${weight}`;

  return (
    <div
      role="button"
      className="flex w-fit flex-col items-start justify-start gap-2 rounded px-4 py-3 focus-visible:border-2 focus-visible:border-blue focus-visible:bg-blue/50 focus-visible:outline-blue"
      onClick={
        args === undefined
          ? () => handler
          : handler !== undefined
            ? // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              () => handlerFunction?.(args)
            : undefined
      }
    >
      <ButtonFill {...props}>
        <>
          {icon ? <span className={iconStyle}>{icon}</span> : null}
          {label ? (
            <ButtonLabel size={size} weight={weight} label={label} />
          ) : null}
        </>
      </ButtonFill>
    </div>
  );
}
