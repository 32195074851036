import { FORM_ACTIONS } from "../../constants";
import {
  BooleanInput,
  DoubleInput,
  FieldNames,
  FormActionType,
  ImageInput,
  InputProps,
  SingleInput,
  TripleInput,
} from "../../types";
import { FieldIcon } from "./FieldIcon";

const inputStyle =
  "relative hidden min-w-0 flex-1 basis-0 items-center self-stretch peer-has-[:checked]:flex";

const inputAction = (
  type: FORM_ACTIONS,
  name?: FieldNames,
  value?: string | number | ArrayBuffer,
): FormActionType => {
  return {
    type: type,
    field: { name: name, value: value },
  };
};

console.log(
  inputAction(FORM_ACTIONS.SET_INPUT, FieldNames.casttime, "1 action"),
);

export function TextInput(props: InputProps<SingleInput<string>>) {
  const { fieldid, field, icon, handler } = props;
  return (
    <div className="relative hidden min-w-0 flex-1 basis-0 items-center self-stretch peer-has-[:checked]:flex">
      <FieldIcon icon={icon ? icon[0] : undefined} />
      <input
        id={`${fieldid}-input`}
        name={fieldid}
        value={field.value}
        tabIndex={0}
        className="flex-1 gap-8 self-stretch truncate rounded border border-white/30 bg-black bg-transparent p-3 pl-12 text-xl caret-blue focus:bg-white/5 focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-blue md:h-10 md:py-2 md:pl-10 md:pr-2 md:text-md-rem"
        type="text"
        onChange={(e) =>
          handler(inputAction(FORM_ACTIONS.SET_INPUT, fieldid, e.target.value))
        }
      ></input>
    </div>
  );
}
export function NumberInput(props: InputProps<SingleInput<string>>) {
  const { fieldid, field, icon, handler, min, max } = props;

  return (
    <div className={inputStyle}>
      <FieldIcon icon={icon ? icon[0] : undefined} />
      <input
        id={`${fieldid}-input`}
        name={fieldid}
        value={field.value}
        tabIndex={0}
        className={`'flex-1 'gap-8 self-stretch truncate rounded border border-white/30 bg-black bg-transparent p-3 pl-12 text-xl caret-blue focus:bg-white/5 focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-blue md:h-10 md:max-w-52 md:flex-auto md:py-2 md:pl-10 md:pr-2 md:text-md-rem`}
        type="number"
        min={min}
        max={max}
        onChange={(e) =>
          handler(inputAction(FORM_ACTIONS.SET_INPUT, fieldid, e.target.value))
        }
      ></input>
    </div>
  );
}
/**
 * ### textarea input
 * ---
 * @param {*} props
 * @returns JSX Element
 */
export function TextAreaInput(props: InputProps<SingleInput<string>>) {
  const { fieldid, field, handler } = props;
  return (
    <textarea
      id={`${fieldid}-input`}
      name={fieldid}
      value={field.value}
      className={
        "hidden h-36 w-full min-w-0 self-stretch rounded border border-white/30 bg-black p-3 text-xl caret-blue focus:bg-white/5 focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-blue peer-has-[:checked]:block md:h-auto md:p-2 md:text-md-rem"
      }
      tabIndex={0}
      rows={window.innerWidth < 768 ? 1 : undefined}
      onChange={(e) =>
        handler(inputAction(FORM_ACTIONS.SET_INPUT, fieldid, e.target.value))
      }
    ></textarea>
  );
}
export function SelectInput(props: InputProps<SingleInput<string>>) {
  const { fieldid, icon, field, options, handler } = props;
  return (
    <div className={inputStyle}>
      <FieldIcon icon={icon ? icon[0] : undefined} />
      <select
        id={`${fieldid}-input`}
        name={fieldid}
        value={field.value}
        className="flex-1 gap-8 self-stretch truncate rounded border border-white/30 bg-black p-3 pl-12 text-xl caret-blue focus:bg-white/5 focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-blue md:h-10 md:py-2 md:pl-10 md:pr-2 md:text-md-rem"
        tabIndex={0}
        onChange={(e) =>
          handler(inputAction(FORM_ACTIONS.SET_INPUT, fieldid, e.target.value))
        }
      >
        <option className="bg-black text-white/30" value="">
          ---
        </option>
        {options?.map((item, index) => {
          return (
            <option className="bg-black" key={index} value={item}>
              {item}
            </option>
          );
        })}
      </select>
    </div>
  );
}
export function ColorInput(props: InputProps<SingleInput<string>>) {
  return (
    <input
      id={`${props.fieldid}-input`}
      name={props.fieldid}
      value={props.field.value}
      tabIndex={0}
      className="h-16 flex-1 cursor-pointer bg-black focus:outline focus:outline-2 focus:outline-offset-1 focus:outline-blue"
      type={props.type}
      min={props.min}
      max={props.max}
      onChange={(e) =>
        props.handler(
          inputAction(FORM_ACTIONS.SET_COLOR, props.fieldid, e.target.value),
        )
      }
    ></input>
  );
}
export function FileInput(props: InputProps<ImageInput>) {
  return (
    <div className="flex min-w-0 flex-1">
      <input
        id={`${props.fieldid}-input`}
        name={props.fieldid}
        type="file"
        accept="image/*"
        tabIndex={0}
        className="min-w-0 flex-1 flex-col text-white/60 file:flex file:h-12 file:w-full file:cursor-pointer file:rounded file:bg-white file:font-bold"
        onChange={(e) => {
          const image = e.target.files ? e.target.files[0] : undefined;
          const reader = new FileReader();
          let imageData;
          reader.addEventListener(
            "load",
            () => {
              // convert image file to base64 string
              imageData = reader.result;
              //console.log(reader.result);
              if (imageData !== null) {
                // update form state
                props.handler(
                  inputAction(FORM_ACTIONS.SET_IMAGE, props.fieldid, imageData),
                );
              }
            },
            false,
          );
          if (image === undefined) {
            alert("Sorry, couldn't read the image");
          } else if (image.size > 1048576) {
            alert(
              "Sorry, this file is too big. Please upload an image under 1MB",
            );
          } else {
            reader.readAsDataURL(image);
          }
        }}
      ></input>
    </div>
  );
}
export function CheckboxInput(props: InputProps<BooleanInput>) {
  const { icon, fieldid, field, handler } = props;
  return (
    <div className="relative flex flex-1 items-center justify-end gap-2">
      <label
        className="material-symbols-outlined text-white/30 peer-checked:text-white"
        aria-hidden
      >
        {icon ? icon[0] : undefined}
      </label>
      <input
        name={`${fieldid}-field-checkbox`}
        id={`${fieldid}-input`}
        // value={field.value}
        checked={field.value}
        tabIndex={0}
        className="peer h-12 w-12 cursor-pointer appearance-auto justify-start rounded border-2 border-white bg-black p-1 font-sans text-black checked:accent-white focus:outline focus:outline-2 focus:outline-offset-1 focus:outline-blue"
        type="checkbox"
        onChange={() => {
          handler({
            type: FORM_ACTIONS.SET_CHECKBOX,
            field: { name: fieldid },
          });
        }}
      ></input>
    </div>
  );
}
/**
 * ### Input field for Spell Components
 * ---
 * @param {*} props
 * @returns JSXElement
 */
export function MultiSelectInput(props: InputProps<TripleInput>) {
  // let shortNames = props.options?.map((name) => {
  //   return name.charAt(0).toUpperCase();
  // });
  const { field, icon, options, handler } = props;

  return (
    <fieldset className="flex h-12 flex-1 flex-wrap gap-4 self-stretch px-4 md:px-0">
      <legend className="sr-only">Spell Components</legend>
      {options?.map((item, index) => {
        return (
          <div
            key={index}
            className="flex h-fit w-fit cursor-pointer items-center gap-2 rounded-full border border-white/60 bg-black py-2 pl-4 pr-4 text-xl has-[:checked]:bg-white has-[:checked]:pr-3 has-[:checked]:font-medium has-[:checked]:text-black md:text-md-rem"
            tabIndex={0}
          >
            <span className="material-symbols-outlined">
              {icon ? icon[index] : undefined}
            </span>
            <label
              className="min-w-0 cursor-pointer outline-blue md:w-fit md:border-none md:text-left"
              htmlFor={`multi-select-checkbox${index}`}
            >
              {item}
            </label>
            <input
              id={`multi-select-checkbox${index}`}
              name={`multi-select-checkbox${index}`}
              type="checkbox"
              // value={field.value[index]}
              checked={field.value[index]}
              tabIndex={-1}
              className="peer/checkbox hidden h-6 w-6 appearance-none justify-start rounded-full border border-black/5 bg-black p-1 text-black checked:block checked:accent-blue-dark focus:outline focus:outline-2 focus:outline-offset-1 focus:outline-blue"
              onChange={() =>
                handler({
                  type: FORM_ACTIONS.SET_MULTISELECT,
                  field: { id: index },
                })
              }
            />
          </div>
        );
      })}
    </fieldset>
  );
}
export function InputSelectInput(props: InputProps<DoubleInput>) {
  const { fieldid, subtype, field, min, handler, options, icon } = props;

  return (
    <div className={`${inputStyle} min-w-0 gap-2`}>
      <div className="relative flex min-w-0 flex-[2_2_0%]">
        <FieldIcon icon={icon ? icon[0] : undefined} />
        <input
          id={`${fieldid}-input`}
          name={fieldid}
          type={subtype}
          value={field.value.input}
          tabIndex={0}
          className="min-w-0 flex-1 gap-8 self-stretch truncate rounded border border-white/30 bg-black bg-transparent p-3 text-xl caret-blue autofill:bg-black autofill:text-white focus:bg-white/5 focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-blue md:h-10 md:py-2 md:pl-10 md:pr-2 md:text-md-rem"
          min={min}
          placeholder="--"
          onChange={(e) =>
            handler({
              type: FORM_ACTIONS.SET_INPUTSELECT,
              field: {
                name: fieldid,
                input: "input",
                value: e.target.value,
              },
            })
          }
        ></input>
      </div>
      <div className="relative flex min-w-0 flex-1 basis-0">
        <FieldIcon icon={icon ? icon[1] : undefined} />
        <select
          name={`${fieldid[1]}Select`}
          value={field.value.unit}
          tabIndex={0}
          className="min-w-0 flex-1 gap-8 self-stretch truncate rounded border border-white/30 bg-black p-3 text-xl caret-blue focus:bg-white/5 focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-blue md:h-10 md:py-2 md:pl-10 md:pr-2 md:text-md-rem"
          onChange={(e) =>
            handler({
              type: FORM_ACTIONS.SET_INPUTSELECT,
              field: {
                name: fieldid,
                input: "select",
                value: e.target.value,
              },
            })
          }
        >
          <option className="bg-black" value="">
            --
          </option>
          {options?.map((option, index) => {
            return (
              <option
                className="bg-black"
                key={index}
                value={option.toLowerCase()}
              >
                {option}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
}
