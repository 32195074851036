import { ButtonLabelProps } from "../types";

export const ButtonLabel = ({
  size = "2xl",
  weight = "black",
  ...props
}: ButtonLabelProps): JSX.Element => {
  const { label } = props;
  const style = `text-${size} font-${weight}`;
  return <span className={style}>{label}</span>;
};
