export function Search(props: {
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  searchCards: (query: string) => void;
}) {
  const { query, setQuery, searchCards } = props;
  /**
   * Set search input
   * @param {string} searchQuery - Search submitted to the form
   */
  const handleSearchForm = (searchQuery: string) => {
    setQuery(searchQuery);
    searchCards(searchQuery);
  };

  return (
    <input
      tabIndex={0}
      className="container min-w-0 flex-1 truncate rounded-md border border-black p-1 text-left not-italic text-black caret-purple-dark focus:outline-4 focus:outline-offset-1 focus:outline-purple group-data-[open=true]:block group-data-[open=false]:hidden md:justify-start md:bg-white md:font-sans md:placeholder-shown:bg-white md:focus:outline md:group-data-[open=false]:invisible md:group-data-[open=false]:block md:group-data-[open=false]:w-0"
      type="text"
      value={query}
      placeholder="🔍Search Spell Cards..."
      onChange={(e) => handleSearchForm(e.target.value)}
    ></input>
  );
}
