// function InputSet(props: { children: JSX.Element }) {
//   return <div className="flex gap-2">{props.children}</div>;
// }
export function FieldIcon(props: { icon: string | undefined }) {
  const { icon } = props;
  if (icon) {
    return (
      <span
        id={icon}
        className="material-symbols-outlined absolute left-4 top-4 text-white/30 md:left-2 md:top-2"
        aria-hidden={true}
      >
        {icon}
      </span>
    );
  }
}
