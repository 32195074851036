/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { releaseData } from "./types";

/**
 * Parses the fetched data format into the display format
 * @param releases Array of release data
 * @returns Array of releaseData
 */
export const parseReleases = (releases: unknown[]) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parsedReleases: Array<releaseData> = releases.map((item: any) => {
    const shortDate = item.published_at.slice(2, 10).replaceAll("-", "/");

    return {
      date: shortDate,
      fullDate: item.published_at,
      version: item.tag_name,
      title: item.name,
      body: item.body,
      url: item.html_url,
    } as releaseData;
  });

  return parsedReleases;
};
