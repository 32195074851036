import { DndContext } from "@dnd-kit/core";
import SpellCard from "../SpellCard/SpellCard";
import { Draggable } from "./subcomponents/Draggable";
import { SpellCardInfo } from "../CardCreator/types";

export default function CardTable(props: {
  cards: SpellCardInfo[];
  isCollapse: boolean;
  showIndex: boolean;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  raiseCard: Function;
  sort: string;
  focusedCard: number | undefined;
  focusRef: React.RefObject<HTMLDivElement>;
}) {
  const {
    cards,
    isCollapse,
    showIndex,
    raiseCard,
    sort,
    focusedCard,
    focusRef,
  } = props;

  return (
    <div
      id="card-table"
      className="fixed flex h-full min-h-fit w-full min-w-0 snap-x snap-mandatory flex-row gap-4 overflow-y-hidden overflow-x-scroll px-10 pb-48 pt-20 md:h-screen md:flex-1 md:snap-none md:flex-wrap md:items-center md:justify-center md:self-stretch md:overflow-x-hidden md:overflow-y-scroll md:py-20 md:pl-40 md:pr-24 md:data-[index='open']:pr-72 print:relative print:m-0 print:!block print:w-full print:items-start print:justify-start print:overflow-x-visible print:overflow-y-visible print:p-2"
      //ref={scrollRef}
      data-print="true"
      data-creator={isCollapse ? "close" : "open"}
      data-index={showIndex ? "open" : "close"}
      ref={focusRef}
      // TODO: Figure out how this works with forwardRef????
    >
      {/*Card Table ==================================*/}
      <DndContext
        data-print="true"
        onDragStart={(e) => {
          if (typeof e.active.id === "string") {
            let cardId: string | undefined = e.active.id;
            cardId = cardId.split("-").pop();
            if (typeof cardId === "string") {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              raiseCard(parseInt(cardId));
            } else {
              console.error("Could not parse the card id into a number");
            }
          }
        }}
        /*onDragEnd={(e) => {
              e.over && e.over.id === "trash"
                ? deleteCard(e.active.id, "-")
                : console.log(null);
              cardtable.classList.remove("flex");
            }}*/
      >
        {cards?.map((card) => {
          return (
            <Draggable
              key={card.id}
              id={`${card.id}`}
              sort={sort}
              zindex={card.zindex}
              isFocus={focusedCard === card.id ? true : false}
            >
              <SpellCard id={card.id} context={card} />
            </Draggable>
          );
        })}
      </DndContext>
    </div>
  );
}
