import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import {
  FieldNames,
  FieldTypes,
  FormActionType,
  SpellCardInfo,
} from "../types";
import { ButtonLabel } from "../../Button/subcomponents/ButtonLabel";
import SpellCard from "../../SpellCard/SpellCard";
import { ButtonType, HTMLButtonType } from "../../Button/types";
import Button from "../../Button/Button";
import { FORM_ACTIONS } from "../constants";
import Field from "./Field";

export default function Form(props: {
  createCard: React.FormEventHandler<HTMLFormElement>;
  fieldState: SpellCardInfo;
  dispatch: React.Dispatch<FormActionType>;
  isEditing: boolean;
}): React.ReactElement {
  const { createCard, fieldState, dispatch, isEditing } = props;
  // Color/Image Modal for mobile

  const switchTab = (tab: string) => {
    setTab(tab);
  };

  const screenWidth = window.innerWidth;
  const [tab, setTab] = useState(screenWidth < 768 ? "Appearance" : "Details");

  const tabs = ["Appearance", "Details"];

  return (
    <motion.form
      id="card-creator-form"
      className="container flex grow flex-col self-start overflow-hidden md:overflow-visible md:pt-10"
      name="card-creator-form"
      onSubmit={createCard}
    >
      <div
        id="creator-tabs"
        className="flex justify-around self-stretch pb-4 md:hidden"
      >
        {tabs.map((item) => (
          <li
            key={item}
            className="relative flex items-center justify-center gap-2 rounded-3xl px-6 py-2 text-center text-xl font-bold text-white"
            onClick={() => switchTab(item)}
          >
            <ButtonLabel label={item} weight="semibold" />

            {item === tab ? (
              <motion.div
                transition={{ duration: 0.1 }}
                className="absolute h-full w-full rounded-3xl border-2 border-blue"
                layoutId="underline"
              />
            ) : null}
          </li>
        ))}
      </div>
      <div
        id="tab-wrapper"
        className="flex shrink grow basis-0 items-center gap-10 self-stretch overflow-x-hidden pb-10 data-[tab='appearance']:justify-start data-[tab='details']:justify-end md:overflow-visible"
        data-tab={tab}
      >
        <AnimatePresence mode="wait" initial={false}>
          {tab === "Details" ? (
            <motion.div
              key="Details"
              initial={{ x: 300, opacity: 0 }}
              exit={{ x: 0, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.1 }}
              id="form-fields"
              className="flex h-fit w-full flex-col gap-4 divide-y divide-white/50 self-stretch"
            >
              <div id="spellname-field" className="self-stretch px-0">
                <Field
                  fieldid={FieldNames.name}
                  fieldtitle="Spell Name"
                  label="hidden"
                  type={FieldTypes.Text}
                  field={fieldState[FieldNames.name]}
                  handler={dispatch}
                  icon={["history_edu"]}
                />
              </div>
              {/*Header Inputs============================*/}
              <CreatorHeader
                fields={
                  <>
                    <Field
                      fieldid={FieldNames.school}
                      fieldtitle="School"
                      type={FieldTypes.Select}
                      label="hidden"
                      options={[
                        "Abjuration",
                        "Conjuration",
                        "Divination",
                        "Enchantment",
                        "Evocation",
                        "Illusion",
                        "Necromancy",
                        "Transmutation",
                      ]}
                      field={fieldState[FieldNames.school]}
                      handler={dispatch}
                      icon={["school"]}
                    />
                    <Field
                      fieldid={FieldNames.level}
                      fieldtitle="Level"
                      label="hidden"
                      type={FieldTypes.Number}
                      min={0}
                      max={9}
                      field={fieldState[FieldNames.level]}
                      handler={dispatch}
                      icon={["expand_circle_up"]}
                    />
                    <Field
                      fieldid={FieldNames.type}
                      fieldtitle="Type"
                      label="hidden"
                      type={FieldTypes.Select}
                      options={["Spell Save", "Spell Attack", "Summon"]}
                      field={fieldState[FieldNames.type]}
                      handler={dispatch}
                      icon={["atr"]}
                    />
                  </>
                }
              />
              {/*Description Inputs============================*/}
              <CreatorBody
                fields={
                  <>
                    <Field
                      fieldid={FieldNames.casttime}
                      fieldtitle="Casting Time"
                      type={FieldTypes.Text}
                      field={fieldState[FieldNames.casttime]}
                      handler={dispatch}
                      icon={["timer"]}
                    />
                    <Field
                      fieldid={FieldNames.range}
                      fieldtitle="Range"
                      type={FieldTypes.InputSelect}
                      subtype={FieldTypes.Text}
                      min={0}
                      options={["ft", "ft radius", "miles", "m", "km"]}
                      field={fieldState[FieldNames.range]}
                      handler={dispatch}
                      icon={["arrow_range", "straighten"]}
                    />
                    <Field
                      fieldid={FieldNames.duration}
                      fieldtitle="Duration"
                      type={FieldTypes.Text}
                      field={fieldState[FieldNames.duration]}
                      handler={dispatch}
                      icon={["hourglass"]}
                    />
                    <Field
                      fieldid={FieldNames.target}
                      fieldtitle="Target"
                      type={FieldTypes.Text}
                      field={fieldState[FieldNames.target]}
                      handler={dispatch}
                      icon={["target"]}
                    />
                    <Field
                      fieldid={FieldNames.damage}
                      fieldtitle="Damage"
                      type={FieldTypes.InputSelect}
                      subtype={FieldTypes.Number}
                      min={1}
                      options={["d4", "d6", "d8", "d10", "d12", "d20"]}
                      field={fieldState[FieldNames.damage]}
                      handler={dispatch}
                      icon={["tag", "casino"]}
                    />
                    <Field
                      fieldid={FieldNames.desc}
                      fieldtitle="Description"
                      type={FieldTypes.TextArea}
                      field={fieldState[FieldNames.desc]}
                      handler={dispatch}
                    />
                    <Field
                      fieldid={FieldNames.upcast}
                      fieldtitle="Upcast"
                      type={FieldTypes.TextArea}
                      field={fieldState[FieldNames.upcast]}
                      handler={dispatch}
                    />
                  </>
                }
              />
              {/*Footer Inputs==================================*/}
              <CreatorFooter
                fields={
                  <>
                    <Field
                      fieldid={FieldNames.comp}
                      fieldtitle="Spell Components"
                      label="hidden"
                      type={FieldTypes.MultiSelect}
                      options={["Verbal", "Somatic", "Material"]}
                      field={fieldState[FieldNames.comp]}
                      handler={dispatch}
                      icon={["voice_selection", "front_hand", "diamond"]}
                    />
                    <Field
                      fieldid={FieldNames.ritual}
                      fieldtitle="Ritual"
                      type={FieldTypes.CheckBox}
                      field={fieldState[FieldNames.ritual]}
                      handler={dispatch}
                      icon={["light_mode"]}
                    />
                    <Field
                      fieldid={FieldNames.con}
                      fieldtitle="Concentration"
                      type={FieldTypes.CheckBox}
                      field={fieldState[FieldNames.con]}
                      handler={dispatch}
                      icon={["cognition_2"]}
                    />
                  </>
                }
              />
              {screenWidth > 768 ? (
                <AppearanceFields
                  fields={
                    <>
                      <Field
                        fieldid={FieldNames.color}
                        fieldtitle="Color"
                        type={FieldTypes.Color}
                        field={fieldState[FieldNames.color]}
                        handler={dispatch}
                      />
                      <Field
                        fieldid={FieldNames.img}
                        fieldtitle="Image"
                        type={FieldTypes.File}
                        field={fieldState[FieldNames.img]}
                        handler={dispatch}
                      />
                    </>
                  }
                />
              ) : null}
            </motion.div>
          ) : (
            <motion.div
              key="Appearance"
              initial={{ x: -300, opacity: 0 }}
              exit={{ x: 0, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.2 }}
              className="container flex h-fit shrink-0 flex-col items-center divide-y divide-white/50 self-stretch"
            >
              <div className="flex flex-col items-center gap-4 self-stretch pb-4">
                <SpellCard id={-2} context={fieldState} isFocus={false} />
                <div className="self-start">
                  <Button
                    type={ButtonType.Underlined}
                    handlerFunction={switchTab}
                    args={"Details"}
                    label="Edit Details →"
                    icon="edit"
                    weight="semibold"
                  />
                </div>
              </div>

              <AppearanceFields
                fields={
                  <>
                    <Field
                      fieldid={FieldNames.color}
                      fieldtitle="Color"
                      type={FieldTypes.Color}
                      field={fieldState[FieldNames.color]}
                      handler={dispatch}
                    />
                    <Field
                      fieldid={FieldNames.img}
                      fieldtitle="Image"
                      type={FieldTypes.File}
                      field={fieldState[FieldNames.img]}
                      handler={dispatch}
                    />
                  </>
                }
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <div
        id="form-controls"
        className="container z-10 flex flex-row items-center justify-center gap-4 self-stretch bg-black py-5"
      >
        <Button
          icon="restart_alt"
          size="3xl"
          type={ButtonType.None}
          handlerFunction={dispatch}
          args={{ type: FORM_ACTIONS.RESET }}
        />

        <Button
          label={isEditing ? "Update Card" : "Create Card"}
          type={ButtonType.Outlined}
          size="3xl"
          htmlType={HTMLButtonType.submit}
          handler={() => {}}
        />
      </div>
    </motion.form>
  );
}

function CreatorHeader(props: { fields: React.ReactElement }) {
  return (
    <div
      id="header-inputs"
      className="flex w-full min-w-0 flex-col gap-4 px-0 pt-6 md:justify-start md:gap-2"
    >
      {props.fields}
    </div>
  );
}

function CreatorBody(props: { fields: React.ReactElement }) {
  return (
    <div
      id="description-inputs"
      className="flex flex-col items-start justify-start gap-2 self-stretch px-0 py-6 leading-5 md:gap-2"
    >
      {props.fields}
    </div>
  );
}

function AppearanceFields(props: { fields: React.ReactElement }) {
  return (
    <div
      id="appearance-fields"
      className="mx-0 mb-4 flex min-w-0 flex-1 flex-col items-stretch gap-4 self-stretch overflow-hidden rounded-sm bg-cover px-0 py-4 md:flex md:w-full md:justify-start md:gap-3 md:self-stretch md:overflow-visible md:bg-transparent"
    >
      {props.fields}
    </div>
  );
}

function CreatorFooter(props: { fields: React.ReactElement }) {
  return (
    <div
      id="footer-fields"
      className="flex w-full min-w-0 flex-col items-start justify-start gap-4 border-t border-black/10 pb-3 pt-2 md:gap-3 md:pt-6"
    >
      {props.fields}
    </div>
  );
}

export { Form };
