import { ReactElement } from "react";

export function UpdateLogSection(props: {
  id: string;
  summary: string;
  icon: string;
  children: ReactElement;
  defaultOpen?: boolean;
}) {
  const { id, summary, icon, children, defaultOpen } = props;

  return (
    <details id={id} className="group/how-to self-stretch" open={defaultOpen}>
      <summary
        id={`${id}-summary`}
        className="flex items-center justify-between self-stretch border-b border-white/30 bg-black py-3"
      >
        <div id={`${id}-title`} className="flex items-center gap-2">
          <span id={`${id}-title-icon`} className="material-symbols-outlined">
            {icon}
          </span>
          <h3 id={`${id}-title-text`} className="text-2xl font-bold">
            {summary}
          </h3>
        </div>
        <span
          id={`${id}-details-arrow`}
          className="material-symbols-outlined group-open/how-to:rotate-180"
        >
          keyboard_arrow_down
        </span>
      </summary>
      <div
        id={`${id}-contents`}
        className="flex flex-col justify-start gap-8 overflow-auto py-4"
      >
        {children}
      </div>
    </details>
  );
}
