import {
  BooleanInput,
  DoubleInput,
  FieldTypes,
  ImageInput,
  InputProps,
  SingleInput,
  TripleInput,
} from "../../types";
import { FieldLabel } from "./FieldLabel";
import { FieldToggle } from "./FieldToggle";
import {
  NumberInput,
  TextAreaInput,
  SelectInput,
  InputSelectInput,
  MultiSelectInput,
  CheckboxInput,
  ColorInput,
  FileInput,
  TextInput,
} from "./Inputs";

// Tailwind Style variables
// *const inputStyleBase =
//   "h-11 rounded-md border border-white bg-black p-1 text-xl caret-blue-dark focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-blue md:h-fit md:text-md-rem ";
// *const toggleMixin = "hidden peer-has-[:checked]:block";

export default function Field(
  props: InputProps<
    SingleInput<string> | DoubleInput | TripleInput | BooleanInput | ImageInput
  >,
) {
  //let fieldState = props.fieldid;
  //let fieldStates = props.fieldStates;
  //let newFieldStates = { ...fieldStates }
  const { field, ...rest } = props;
  const isOptional: boolean | undefined =
    field !== undefined && typeof field !== "number"
      ? field.optional
      : undefined;

  let inputs = <></>;

  switch (props.type) {
    case FieldTypes.Number:
      inputs = <NumberInput field={field as SingleInput<string>} {...rest} />;
      break;
    case FieldTypes.TextArea:
      inputs = <TextAreaInput field={field as SingleInput<string>} {...rest} />;
      break;
    case FieldTypes.Select:
      inputs = <SelectInput field={field as SingleInput<string>} {...rest} />;
      break;
    case FieldTypes.InputSelect:
      inputs = <InputSelectInput field={field as DoubleInput} {...rest} />;
      break;
    case FieldTypes.MultiSelect:
      inputs = <MultiSelectInput field={field as TripleInput} {...rest} />;
      break;
    case FieldTypes.CheckBox:
      inputs = <CheckboxInput field={field as BooleanInput} {...rest} />;
      break;
    case FieldTypes.Color:
      inputs = <ColorInput field={field as SingleInput<string>} {...rest} />;
      break;
    case FieldTypes.File:
      inputs = <FileInput field={field as ImageInput} {...rest} />;
      break;
    case FieldTypes.Text:
      inputs = <TextInput field={field as SingleInput<string>} {...rest} />;
      break;
    default:
      inputs = <TextInput field={field as SingleInput<string>} {...rest} />;
  }

  return (
    <div
      id={`${props.fieldid}-field`}
      className={
        props.type === FieldTypes.CheckBox ||
        props.type === FieldTypes.File ||
        props.type === FieldTypes.Color
          ? "flex w-full items-center justify-between md:gap-4"
          : "flex w-full flex-col items-start justify-start gap-1"
      }
    >
      <div
        id={`${props.fieldid}-field-label`}
        className={
          isOptional
            ? "group/label peer flex items-center justify-between self-stretch"
            : "group/label peer flex w-fit items-center justify-between"
        }
      >
        <FieldLabel label={props.fieldtitle} labelFor={props.fieldid} />
        <FieldToggle {...props} />
      </div>
      {inputs}
    </div>
  );
}
