import { useState } from "react";
import { motion } from "framer-motion";

export function SpellbookEntry(props: {
  order: number;
  id: string | number | undefined;
  name: string;
  level: string;
  school: string;
  handleEdit: (id: string) => void;
  handleCopy: (id: string, order: number) => Promise<void>;
  handleDelete: (id: string, splitChar: string) => Promise<void>;
  isEditing: boolean;
  raiseCard: (id: number) => void;
  focusCard: (id: number) => void;
}) {
  const [hasIndexOptions, setHasIndexOptions] = useState<boolean>();

  // * Set level to "cantrip" if level=0
  let spellLevel;

  if (props.level === "0") {
    spellLevel = "Cantrip";
    //console.log(spellLevel);
  } else {
    spellLevel = `Level ${props.level}`;
  }

  const handleClick = () => {
    console.log(props.id);
    if (typeof props.id === "number") {
      setHasIndexOptions(!hasIndexOptions);
      props.raiseCard(props.id);
    } else if (typeof props.id === "string") {
      const parsedId = parseInt(props.id);

      setHasIndexOptions(!hasIndexOptions);
      props.raiseCard(parsedId);
    }
  };

  return (
    <motion.div
      id={`cardindex-${props.id}`}
      //!!!!! Changed this!!!
      data-order={props.order}
      className="focus-visibile:outline-width-3 group/cardindex relative flex w-[100%] flex-col items-start justify-start gap-2.5 px-8 py-2.5 outline-purple focus-within:bg-purple/30 hover:bg-purple/10"
      tabIndex={0}
      onClick={() => handleClick()}
      onFocus={() => {
        if (typeof props.id === "string") {
          props.focusCard(parseInt(props.id));
        } else if (typeof props.id === "number") {
          props.focusCard(props.id);
        }
      }}
      onBlur={() => props.focusCard(NaN)}
    >
      <div
        id={`cardindex-${props.id}-info`}
        className="container flex flex-col justify-start gap-1"
      >
        <p
          id={`card-name-${props.id}`}
          className="font-sans text-2xl font-medium text-black group-hover/cardindex:font-bold"
        >
          {props.name}
        </p>
        <p
          id={`card-info-${props.id}`}
          className="text-sm font-normal italic text-black opacity-70 group-hover/cardindex:font-medium"
        >
          {props.school} | {spellLevel}
        </p>
      </div>
      <span
        id={`options-button-${props.id}`}
        className="material-symbols-outlined absolute right-4 top-2 cursor-pointer text-2xl opacity-70"
      >
        {hasIndexOptions ? "more_horiz" : "more_vert"}
      </span>

      <div
        id={`cardindex-options${props.id}`}
        className={
          hasIndexOptions
            ? "mb-1 inline-flex items-start justify-start gap-4"
            : "hidden"
        }
      >
        <IndexButton
          btnName="Edit"
          id={props.id}
          handleButton={props.handleEdit}
          isEditing={props.isEditing}
        />

        <IndexButton
          btnName="Copy"
          id={props.id}
          order={props.order}
          handleButton={props.handleCopy}
        />

        <IndexButton
          btnName="Delete"
          id={props.id}
          handleButton={props.handleDelete}
        />
      </div>
    </motion.div>
  );
}

export function IndexButton(props: {
  btnName: string;
  id: string | number | undefined;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  handleButton: Function;
  isEditing?: boolean;
  order?: number;
}) {
  const idName = props.btnName.replaceAll(" ", "-");

  if (
    props.btnName === "Edit" ||
    props.btnName === "Export Cards" ||
    props.btnName === "Clear All Cards"
  ) {
    return (
      <button
        id={`${idName}-btn_${props.id}`}
        name={`${props.btnName}-btn_${props.id}`}
        className="from-purple from-0% via-purple via-50% to-50% font-sans text-base text-md-rem font-normal text-black underline hover:text-purple-dark focus-visible:bg-gradient-to-t active:bg-gradient-to-t active:from-purple active:from-0% active:via-purple active:via-50% active:to-50% disabled:opacity-50 disabled:hover:text-black disabled:active:bg-none md:pointer-events-auto"
        tabIndex={0}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
        onClick={(e) => props.handleButton((e.target as HTMLElement).id)}
        disabled={props.isEditing === true ? true : false}
      >
        {props.btnName}
      </button>
    );
  } else if (props.btnName === "Copy") {
    return (
      <button
        id={`${idName}-btn_${props.id}`}
        name={`${props.btnName}-btn_${props.id}`}
        className="from-purple from-0% via-purple via-50% to-50% font-sans text-base font-normal text-black underline hover:text-purple-dark focus-visible:bg-gradient-to-t active:bg-gradient-to-t active:from-purple active:from-0% active:via-purple active:via-50% active:to-50% disabled:opacity-50 disabled:hover:text-black disabled:active:bg-none"
        tabIndex={0}
        onClick={(e) =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
          props.handleButton((e.target as HTMLElement).id, props.order)
        }
      >
        {props.btnName}
      </button>
    );
  } else {
    // Delete button
    return (
      <button
        id={`${idName}-btn_${props.id}`}
        name={`${props.btnName}-btn_${props.id}`}
        className="text-nowrap from-purple from-0% via-purple via-50% to-50% font-sans text-base font-normal text-black underline hover:text-purple-dark focus-visible:bg-gradient-to-t active:bg-gradient-to-t active:from-purple active:from-0% active:via-purple active:via-50% active:to-50%"
        tabIndex={0}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
        onClick={(e) => props.handleButton((e.target as HTMLElement).id, "_")}
      >
        {props.btnName}
      </button>
    );
  }
}
