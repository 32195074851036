import { DualInput, FieldNames, SpellCardInfo } from "../CardCreator/types";

/**
 * Converts property values to strings
 * @param {*} input - Property value
 * @param {string} objInput - Input property of value Object
 * @param {string} objUnit - Unit property of value Object
 * @returns property value as a string
 */
export function parseValues(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  input: any,
  objInput?: string | number,
  objUnit?: string,
): string {
  if (input instanceof Array) {
    //console.log("Instance of Array Detected");

    return input
      .map((component, index) => {
        if (index === 0 && component === true) {
          return "verbal";
        } else if (index === 1 && component === true) {
          return "somatic";
        } else if (index === 2 && component === true) {
          return "material";
        } else {
          return "";
        }
      })
      .filter(Boolean)
      .toString()
      .replaceAll(" ", "");
  } else if (input instanceof Object) {
    return `${objInput}${objUnit}`;
  } else {
    return JSON.stringify(input).toLowerCase();
  }
}

/**
 * Checks if an array's entries contain a string
 * @param {Array} array
 * @param {string} query
 * @returns true/false
 */
export function arrContainsQuery(array: Array<string>, query: string) {
  let containsQuery;
  array.forEach((string: string) => {
    // console.log(string);
    // console.log(string.indexOf(query));
    if (string.indexOf(query.toLowerCase()) > -1) {
      containsQuery = true;
    }
  });
  return containsQuery ? true : false;
}

/**
 * Searches object props if query starts with "*:"
 * @param {Object} card - Card being searched
 * @param {string} query - Search query
 * @returns true/false
 */
export function searchByProp(card: SpellCardInfo, query: string) {
  //console.log(card);
  const propName = query
    .split(":")
    .shift()
    ?.trim()
    .replaceAll(" ", "")
    .toLowerCase() as FieldNames;
  const propVal = query.split(":").pop()?.trim();
  const altPropName = ("spell" + propName) as FieldNames;

  console.log(propName + ":" + propVal);

  let cardVal;
  if (card[`${propName}`] !== undefined) {
    if (typeof card[`${propName}`].value === "object") {
      const cardProps = card[`${propName}`].value as DualInput;
      cardVal = parseValues(
        card[`${propName}`].value,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        cardProps.input,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        cardProps.unit,
      );
    } else {
      cardVal = parseValues(card[`${propName}`].value);
    }
  } else if (card[`${altPropName}`] !== undefined) {
    cardVal = card[`${altPropName}`]["value"] as string;
  }

  let cardVis;
  if (card[`${propName}`] !== undefined) {
    cardVis = card[`${propName}`]["visibility"];
  } else if (card[`${altPropName}`] !== undefined) {
    cardVis = card[`${altPropName}`]["visibility"];
  }
  console.log("propVal = " + propVal);
  console.log("cardVal = " + cardVal);

  console.log("Querying " + propName + "...");

  if (
    cardVal &&
    propVal?.localeCompare(cardVal, undefined, { sensitivity: "accent" }) === 0
  ) {
    console.log("Exact match was found");
    return true;
  } else if (
    cardVal &&
    cardVal.toLowerCase().indexOf(propVal?.toLowerCase() as string) > -1 &&
    // **** This might need work
    propVal !== ""
  ) {
    console.log("Index was found");
    return true;
  } else if (propVal === "none" && cardVis === false) {
    // console.log("None was found");
    return true;
  } else {
    //console.warn("No " + propName + " values match " + propVal + " on this card");
  }
}
