import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import Form from "./Form";
import { Dispatch, FormEventHandler, MouseEventHandler } from "react";
import Button from "../Button/Button";
import SpellCard from "../SpellCard/SpellCard";
import { ButtonType } from "../Button/types";
import { FormActionType, SpellCardInfo } from "./types";

export default function CardCreator(props: {
  isCollapse: boolean;
  toggleCreator: MouseEventHandler;
  toggleInfoModal: MouseEventHandler;
  createCard: FormEventHandler;
  isEditing: boolean;
  cancelEdit: MouseEventHandler;
  dispatch: Dispatch<FormActionType>;
  fieldStates: SpellCardInfo;
}) {
  const {
    isCollapse,
    toggleCreator,
    toggleInfoModal,
    createCard,
    isEditing,
    cancelEdit,
    dispatch,
    fieldStates,
  } = props;

  return (
    <motion.div
      className="absolute top-0 z-30 flex w-full flex-col md:relative md:w-auto md:flex-row"
      data-print="false"
    >
      <LayoutGroup id="layoutgroup">
        <motion.div
          id="card-creator"
          layout="position"
          layoutRoot
          transition={{ layout: { duration: 0.5, damping: 300 } }}
          className={
            isCollapse
              ? "group relative z-30 flex h-fit w-full justify-start !overflow-hidden bg-black px-8 py-6 font-sans text-white *:hidden md:h-screen md:!w-fit md:px-16 md:py-16"
              : "group relative z-30 mb-4 flex h-dvh w-full shrink-0 flex-col items-start justify-start gap-6 overflow-y-hidden bg-black px-8 py-6 font-sans text-white md:mb-auto md:h-screen md:w-auto md:resize-x md:overflow-y-scroll md:px-16 md:py-16"
          }
          data-open={isCollapse ? "false" : "true"}
        >
          {/** Card Creator Header */}
          <motion.div
            id="card-creator-header"
            className={
              isCollapse
                ? "!flex grow flex-row-reverse !justify-between self-stretch md:flex-col"
                : "!flex !justify-end self-stretch"
            }
          >
            <motion.h1
              className={
                isCollapse
                  ? "hidden flex-1 text-wrap font-sans text-3xl font-bold text-white opacity-80"
                  : "flex-1 text-wrap font-sans text-3xl font-bold text-white md:text-4xl"
              }
            >
              Spell Card Wizard &#129668;
            </motion.h1>
            <motion.button
              id="collapse-button"
              className={
                isCollapse
                  ? "material-symbols-outlined focus-visible:outline-3 !block !rotate-45 text-4xl transition-transform focus-visible:text-blue focus-visible:outline-blue active:text-blue"
                  : "material-symbols-outlined focus-visible:outline-3 !block text-4xl transition-transform focus-visible:text-blue focus-visible:outline-blue active:text-blue"
              }
              tabIndex={0}
              onClick={toggleCreator}
            >
              close
            </motion.button>

            {isCollapse ? (
              <Button
                type={ButtonType.None}
                icon="help"
                size="4xl"
                handler={toggleInfoModal}
              />
            ) : null}
          </motion.div>

          {/** Card Creator Form*/}
          <Form
            createCard={createCard}
            isEditing={isEditing}
            fieldState={fieldStates}
            dispatch={dispatch}
          />
        </motion.div>

        {/*Card Preview*/}
        <div id="card-preview-wrapper" className="relative hidden w-0 md:flex">
          <AnimatePresence>
            {isCollapse ? null : (
              <motion.div
                id="card-preview-modal"
                className="sticky z-20 mt-5 flex max-h-[90%] w-screen flex-col items-center gap-6 self-center rounded-md bg-white/50 px-20 pb-20 pt-10 shadow-xl backdrop-blur-md md:ml-10 md:w-auto"
                key="preview-modal"
                initial={{ x: -1000 }}
                animate={{ x: 0 }}
                exit={{ x: -1000 }}
                transition={{ duration: 0.2 }}
              >
                <div
                  id="preview-title"
                  className="flex flex-col items-center gap-2"
                >
                  <p
                    id="preview-text"
                    className={
                      isEditing
                        ? "animate-pulse font-sans text-3xl font-bold text-black"
                        : "font-sans text-3xl font-bold text-black"
                    }
                  >
                    {isEditing ? "Editing" : "New Card"}
                  </p>
                  <button
                    id="cancel-edit-button"
                    className={
                      isEditing
                        ? "font-sans underline hover:text-purple-dark focus-visible:bg-purple active:bg-purple disabled:opacity-50"
                        : "hidden"
                    }
                    aria-label="cancel edit"
                    tabIndex={0}
                    onClick={cancelEdit}
                  >
                    [Cancel]
                  </button>
                </div>
                <div className="pointer-events-none contents">
                  <SpellCard id={-1} context={fieldStates} isFocus={false} />
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </LayoutGroup>
    </motion.div>
  );
}
