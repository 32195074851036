// * Types
export enum UPDATE {
  MAJOR = "Major",
  MINOR = "Minor",
  PATCH = "Patch",
}

export type releaseData = {
  update?: UPDATE;
  date: string;
  fullDate: string;
  version: string;
  title: string;
  body: string;
  url: string;
};
