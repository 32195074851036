/**
 * ### Label for <Field/> Element
 * ---
 * @param {FieldProps} props
 * @returns {JSX.Element}
 */
export function FieldLabel(props: {
  label?: string;
  labelFor: string;
}): JSX.Element {
  return (
    <label
      htmlFor={`${props.labelFor}-input`}
      id={`${props.labelFor}-label`}
      className={
        "min-w-24 shrink text-base font-semibold opacity-50 group-has-[:checked]/label:opacity-100 md:inline-block md:min-w-36 md:py-0 md:text-base"
      }
    >
      {props.label}:
    </label>
  );
}
