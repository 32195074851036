export function CardField(props: {
  name: string;
  visibility?: boolean;
  optional: boolean;
  value: string;
  label: string;
}) {
  return (
    <li
      id={`${props.name}-field`}
      className={
        props.optional ? (props.visibility ? "flex" : "hidden") : "flex"
      }
    >
      <strong>{props.label}&nbsp;</strong>
      {props.value}
    </li>
  );
}
