import { FORM_ACTIONS } from "./constants";

export type DualInput = {
  [index: string]: string | number;
  input: number | string;
  unit: string;
};

export interface Field<T> {
  [index: string]: T | boolean;
  value: T;
  visibility: boolean;
  optional: boolean;
}

interface InputBaseType {
  visibility: boolean;
  optional: boolean;
}

export interface SingleInput<T> extends InputBaseType {
  value: T;
}

export interface DoubleInput extends InputBaseType {
  value: { input: number | string; unit: string };
}

export interface TripleInput extends InputBaseType {
  value: [boolean, boolean, boolean];
}

export interface BooleanInput extends InputBaseType {
  value: boolean;
}

export interface ImageInput extends InputBaseType {
  value: string | ArrayBuffer | number;
}

export interface SpellCardFields {
  [FieldNames.name]: SingleInput<string>;
  [FieldNames.level]: SingleInput<string>;
  [FieldNames.school]: SingleInput<string>;
  [FieldNames.type]: SingleInput<string>;
  [FieldNames.casttime]: SingleInput<string>;
  [FieldNames.range]: DoubleInput;
  [FieldNames.duration]: SingleInput<string>;
  [FieldNames.target]: SingleInput<string>;
  [FieldNames.damage]: DoubleInput;
  [FieldNames.desc]: SingleInput<string>;
  [FieldNames.upcast]: SingleInput<string>;
  [FieldNames.comp]: TripleInput;
  [FieldNames.ritual]: BooleanInput;
  [FieldNames.con]: BooleanInput;
  [FieldNames.color]: SingleInput<string>;
  [FieldNames.img]: ImageInput;
}

export interface SpellCardInfo extends SpellCardFields {
  id?: number;
  zindex?: number;
}

export interface ActionField<T> {
  name?: FieldNames;
  input?: string;
  value?: T;
  id?: string | number;
}

export type FormActionType = {
  type: FORM_ACTIONS;
  field?: {
    name?: FieldNames;
    input?: string;
    value?: string | ArrayBuffer | number | boolean;
    id?: string | number;
  };
  payload?: SpellCardInfo;
};

export enum FieldTypes {
  Text = "TEXT",
  Number = "NUMBER",
  Select = "SELECT",
  TextArea = "TEXTAREA",
  InputSelect = "INPUTSELECT",
  MultiSelect = "MULTISELECT",
  CheckBox = "CHECKBOX",
  Color = "COLOR",
  File = "FILE",
}

export enum FieldNames {
  name = "spellname",
  level = "spelllevel",
  school = "spellschool",
  type = "spelltype",
  casttime = "casttime",
  range = "range",
  duration = "duration",
  target = "spelltarget",
  damage = "damage",
  desc = "spelldesc",
  upcast = "upcastdesc",
  comp = "spellcomp",
  ritual = "spellritual",
  con = "spellcon",
  color = "spellcolor",
  img = "spellimg",
}

export interface FieldProps {
  type: FieldTypes;
  fieldid: FieldNames;
  fieldState: SpellCardFields;
  handler: React.Dispatch<FormActionType>;
  icon?: string[];
  label?: string;
  size?: string;
  fieldtitle?: string;
  min?: number;
  max?: number;
  options?: string[];
  subtype?: FieldTypes;
}

interface BaseFieldProps {
  type: FieldTypes;
  fieldid: FieldNames;
  handler: React.Dispatch<FormActionType>;
  icon?: string[];
  label?: string;
  size?: string;
  fieldtitle?: string;
  min?: number;
  max?: number;
  options?: string[];
  subtype?: FieldTypes;
}

export interface InputProps<T> extends BaseFieldProps {
  field: T;
}
