import { useState } from "react";
import { FORM_ACTIONS } from "../../constants";
import {
  BooleanInput,
  DoubleInput,
  ImageInput,
  InputProps,
  SingleInput,
  TripleInput,
} from "../../types";

/**
 * ### Controls whether field is included or not
 * ---
 * @param {*} props
 * @returns JSXElement and fieldState
 */
export function FieldToggle(
  props: InputProps<
    SingleInput<string> | DoubleInput | TripleInput | BooleanInput | ImageInput
  >,
) {
  const { fieldid, field, handler } = props;
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckbox = () => {
    setIsChecked(!isChecked);
    handler({
      type: FORM_ACTIONS.SET_VIS,
      field: { name: fieldid },
    });
    console.log(fieldid + " has been clicked");
    console.log(field.visibility);
  };

  // TODO: Add handler for pressing space on checkbox to toggle
  if (field.optional === true) {
    return (
      <>
        <input
          id={`checkbox-${fieldid}`}
          className="peer/checkbox hidden focus:outline focus:outline-2 focus:outline-offset-1 focus:outline-blue"
          type="checkbox"
          name={fieldid}
          // value={field.visibility}
          checked={field.visibility}
          onChange={handleCheckbox}
        />
        <label
          className="material-symbols-outlined color-white flex-1 cursor-pointer text-right text-5xl font-bold opacity-50 before:content-['add'] peer-checked/checkbox:opacity-100 peer-checked/checkbox:before:content-['remove'] md:text-2xl"
          htmlFor={`checkbox-${fieldid}`}
          tabIndex={0}
        ></label>
      </>
    );
  } else {
    return (
      <input
        id={`checkbox-${fieldid}`}
        className="peer/checkbox hidden focus:outline focus:outline-2 focus:outline-offset-1 focus:outline-blue"
        type="checkbox"
        name={fieldid}
        // value={field.visibility}
        checked={field.visibility}
        readOnly
        disabled
        aria-hidden
      />
    );
  }
}
