import { releaseData } from "../types";

export function UpdateLogEntry(props: { updateData: releaseData }) {
  const { updateData } = props;
  console.log(updateData.update);

  return (
    <div
      className="group flex items-baseline justify-start gap-2 self-stretch border-t border-white/20 pb-4 pt-6 data-[type=Minor]:opacity-80 data-[type=Patch]:opacity-70"
      data-type={updateData.update}
    >
      <div
        id="entry-info"
        className="inline-flex w-24 flex-col items-start justify-start gap-2"
      >
        <div
          id="entry-version"
          className="h-fit self-stretch align-baseline opacity-60 group-data-[type=Major]:text-2xl group-data-[type=Minor]:text-xl group-data-[type=Patch]:text-base"
        >
          {updateData.version}
        </div>
        <div id="entry-date" className="self-stretch text-xs opacity-60">
          {updateData.date}
        </div>
      </div>
      <div
        id="entry-details"
        className="inline-flex h-fit shrink grow basis-0 flex-col justify-start gap-4 self-stretch"
      >
        <div
          id="entry-title"
          className="group-data-[type=Major]:text-3xl group-data-[type=Minor]:text-xl group-data-[type=Patch]:text-base"
        >
          {updateData.title}
        </div>
        <div id="entry-body-container" className="flex flex-col gap-2">
          <div id="entry-body" className="line-clamp-3 text-base opacity-70">
            {updateData.body}
          </div>
          <a
            className="text-base underline"
            href={updateData.url}
            target="_blank"
            rel="noreferrer"
          >
            [ See full release notes ]
          </a>
        </div>
      </div>
    </div>
  );
}
